<template>
  <div class="div-home">
    <LayoutLanding>
      <Home />
      <!-- <Loader /> -->
    </LayoutLanding>
  </div>
</template>

<script>
import Home from "@/components/Home.vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import Loader from "@/components/Loader.vue";
import LayoutLanding from "@/components/LayoutLanding.vue";
export default {
  name: "HomeView",
  components: {
    Home,
    Loader,
    LayoutLanding,
  },
  setup(props) {
    const router = useRouter();

    onMounted(() => {});

    return {};
  },
};
</script>
