<template>
  <div class="div-franchise">
    <LayoutLanding>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-content">
              <!-- ***** Details Start ***** -->
              <div class="text-zone">
                <div class="row">
                  <div class="col-lg-10">
                    <h2 class="mt-0">FRANQUICIA</h2>
                    <p class="main-p">
                      Aunque SPARK81 es un proyecto joven, tenemos intención de
                      expandirnos y llevar el entretenimiento activo más allá.
                      Estamos en búsqueda activa de inversión y partners
                      estratégicos que compartan nuestra visión para escalar el
                      proyecto y abrir nuevos centros. Ofrecemos la oportunidad
                      de ser la vanguardia del sector del ocio. ¿Te apuntas?
                    </p>
                  </div>
                </div>
              </div>
              <!-- ***** Details End ***** -->
              <FormContact
                :formSchema="formSchema"
                :buttonFunction="sendData"
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutLanding>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import LayoutLanding from "@/components/LayoutLanding.vue";
import FormContact from "@/components/FormContact.vue";
import { axiosToken } from "../libs/http";
import { notify } from "notiwind";
export default {
  name: "FranchiseView",
  components: {
    LayoutLanding,
    FormContact,
  },
  setup(props) {
    onMounted(() => {});
    const sendData = (data) => {
      data["type_contact"] = 3;
      axiosToken({ method: "post", url: "landing/contact", body: data })
        .then((response) => {
          if (response.status == 201) {
            notify(
              {
                group: "success",
                title: "Exito",
                text: "Te hemos enviado un correo.",
              },
              4000
            );
          } else {
            notify(
              {
                group: "error",
                title: "Error",
                text: "Vaya, algo a ido mal.",
              },
              4000
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const formSchema = ref({
      title: "CONTACTO DE FRANQUICIA",
      description:
        "¿Te interesa formar parte del proyecto? ¿Conocer mejor los números? No dudes en escribirnos.",
      form: [
        {
          type: "input",
          name: "name",
          title: "Nombre y apellidos",
          placeholder: "ej: Elon Musk",
        },
        {
          type: "input",
          name: "email",
          title: "Correo",
          placeholder: "ej: emusk@tesla.com",
        },
        {
          type: "textarea",
          name: "message",
          title: "Mensaje",
          placeholder:
            "ej: Quiero abrir un SPARK81 en Texas y voy a haceros una oferta que no podréis rechazar... *",
        },
        {
          type: "button",
          name: "send",
          title: "Enviar",
        },
      ],
    });

    return { formSchema, sendData };
  },
};
</script>
