<template>
  <div class="loader" v-if="showLoader">
    <div
      @click="hideLoader"
      :style="{ opacity: opacityComponent, visibility: hideComponent }"
      class="splash"
      v-bind="splashScreen"
    >
      <div class="splash-inside container">
        <img src="@/assets/images/Logo-animation-psd-2.gif" alt="" />
        <span CLASS="enter-splash">HAZ CLICK PARA ENTRAR</span>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
// @ is an alias to /src
export default {
  name: "LoaderComponent",
  components: {},
  setup(props) {
    const splashScreen = ref(null);
    const hideComponent = ref("visible");
    const opacityComponent = ref(100);
    const showLoader = ref(false);

    onMounted(() => {
      if (!localStorage.getItem("loader_done")) {
        showLoader.value = true;
      }
    });

    const hideLoader = () => {
      localStorage.setItem("loader_done", true);
      opacityComponent.value = 0;
      setTimeout(() => {
        hideComponent.value = "hidden";
      }, 610);
    };
    return {
      hideLoader,
      hideComponent,
      opacityComponent,
      splashScreen,
      showLoader,
    };
  },
};
</script>
