<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="page-content">
          <!-- ***** Banner Start ***** -->
          <div class="main-banner">
            <div class="overlay-h1">
              <div class="row">
              <div class="col-lg-7">
                <div class="header-text">
                  <h6>SALAS DE ACCIÓN ARCADE</h6>
                  <h4>Descúbre la nueva<br>forma de <em>jugar</em></h4>
                  <h5 class="location2">En Terrassa, Cataluña</h5>
                  <div class="main-button">
                    <a href="#" @click.prevent="() => {$router.push('/booking')}">RESERVA AHORA</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 image text-center p-4 cursor-pointer" @click="openModal">
                <img src="@/assets/images/PLAY2.svg" alt="" />
                <h2 class="video-shadow">VER VIDEO</h2>
              </div>
            </div>
            </div>

          </div>
          <div class="overlay-h2">
            <div class="section-how">
            <div class="row">
              <div class="col-lg-12">
                <h2>¿CÓMO FUNCIONA?</h2>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="item">
                    <p>
                      SPARK81 es un espacio de juegos arcade inmersivos donde cada sala alberga desafíos digitales para jugar en equipo.
                      <br>
                      Combina la acción de un videojuego con tecnología interactiva que te hace saltar, disparar, esquivar, correr…
                      <br>
                      Solo los mejores conseguirán llegar a lo más alto
                      del ránking. ¿Te atreves?
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row item2">
                    <div class="col-2">
                      <img src="@/assets/images/icon(2).png" alt="" />
                    </div>
                    <div class="col-10">
                      <h4>Junta a tu equipo de 2-6 jugadores.</h4>
                    </div>
                  </div>
                  <div class="row item2 item-far">
                    <div class="col-2">
                      <img src="@/assets/images/icon(3).png" alt="" />
                    </div>
                    <div class="col-10">
                      <h4>Reserva a través de la web.</h4>
                    </div>
                  </div>
                  <div class="row item2 item-far">
                    <div class="col-2">
                      <img src="@/assets/images/icon(6).png" alt="" />
                    </div>
                    <div class="col-10">
                      <h4>Trae ropa y calzado cómodo de deporte.</h4>
                    </div>
                  </div>
                  <div class="row item2 item-far">
                    <div class="col-2">
                      <img src="@/assets/images/icon(1).png" alt="" />
                    </div>
                    <div class="col-10">
                      <h4>Juega a SPARK81 durante 60 minutos.</h4>
                    </div>
                  </div>
                  <div class="row item2">
                    <div class="col-2">
                      <img src="@/assets/images/icon(7).png" alt="" />
                    </div>
                    <div class="col-10">
                      <h4>Intenta llegar a lo más alto del ránking.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>

          <div class="most-popular">
            <div class="row">
              <div class="col-lg-12">
                <div class="heading-section">
                  <h4>LAS SALAS</h4>
                </div>
                <div class="row salas-select">
                  <div class="col-lg-9">
                    <div class="item3">
                      <Transition name="room" mode="out-in">
                        <img
                          :key="rooms[roomSelected]"
                          class="answer"
                          :src="rooms[roomSelected].room_src"
                          alt=""
                        />
                      </Transition>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div
                      class="item2 cursor-pointer"
                      v-for="(room, index) in rooms"
                      :key="room"
                      :class="roomSelected == index ? 'siactive' : ''"
                      @click="changeRoomSelected(index)"
                    >
                      <img :src="room.icon_src" alt="" />
                      <h4>{{ room.name }}</h4>
                    </div>
                  </div>
                </div>
                <Transition name="room" mode="out-in">
                  <div
                    class="row"
                    v-if="rooms[roomSelected].challenges.length > 0"
                  >
                    <h3>RETOS</h3>
                  </div>
                  <div class="row" v-else>
                    <div class="col-lg-4 col-sm-6">
                      <h3>PRÓXIMAMENTE</h3>
                    </div>
                  </div>
                </Transition>
                <div class="col">
                  <Transition name="room" mode="out-in">
                    <div
                      class="row"
                      v-if="rooms[roomSelected].challenges.length > 0"
                    >
                      <TransitionGroup name="games" mode="out-in">
                        <div
                          class="col-lg-4 col-sm-6"
                          v-for="challenge in rooms[roomSelected].challenges"
                          :key="challenge"
                        >
                          <div class="item4" :key="challenge.name">
                            <h4>{{ challenge.name }}</h4>
                            <span>{{ challenge.description }}</span>
                          </div>
                        </div>
                      </TransitionGroup>
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
          <Prices />
          <FAQs />
          <Subscribe />
          <!-- ***** Most Popular End ***** -->
        </div>
      </div>
    </div>
    <Modal :isOpen="isModalOpened" @modal-close="closeModal" name="first-modal">
      <template #content><iframe class="w-full aspect-video rounded-3xl" src="https://www.youtube.com/embed/Iil_kxSYrFs?autoplay=1" title="SPARK81 - Salas de acción arcade" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" autoplay  allowfullscreen></iframe></template>
    </Modal>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import FAQs from "./FAQs.vue";
import Prices from "./Prices.vue";
import Subscribe from "./Subscribe.vue";
import Modal from "./Modal.vue";
export default {
  props: {},
  components: { FAQs, Prices, Subscribe , Modal},
  beforeCreate() {},
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const roomSelected = ref(0);
    const changeRoomSelected = (room) => {
      roomSelected.value = room;
    };
    const isModalOpened = ref(false);

  const openModal = () => {
    isModalOpened.value = true;
  };
  const closeModal = () => {
    isModalOpened.value = false;
  };

    const rooms = ref([
      {
        name: "SALA DIANAS",
        icon_src: new URL(
          "/src/assets/images/icons-sala(1).png",
          import.meta.url
        ).href,
        room_src: new URL("/src/assets/images/Dianas-min.png", import.meta.url)
          .href,
        challenges: [
          {
            name: "TIRO AL BLANCO",
            description: "Afina tu puntería acertando en el blanco.",
          },
          {
            name: "PLAGIO",
            description: "Copia un dibujo atinando en las dianas.",
          },
          {
            name: "COLORES ESCONDIDOS",
            description: "Recuerda la posición de los colores.",
          },
          {
            name: "IMITACIÓN",
            description: "Memoriza una secuencia y repítela.",
          },
          {
            name: "RÁFAGA",
            description: "Acierta en las dianas antes de que se apaguen.",
          },
          {
            name: "PISTOLERO",
            description: "(COMPETITIVO) Dale a las dianas de tu color más rápido que tus compañeros.",
          },
        ],
      },
      {
        name: "SALA BOTONES",
        icon_src: new URL(
          "/src/assets/images/icons-sala(2).png",
          import.meta.url
        ).href,
        room_src: new URL("/src/assets/images/Botones-min.png", import.meta.url)
          .href,
        challenges: [
          {
            name: "REACCIÓN RÁPIDA",
            description: "Pulsa los botones antes de que se apaguen.",
          },
          {
            name: "HAZ MEMORIA",
            description: "Recuerda la posición de las parejas de colores.",
          },
          {
            name: "TODOS A UNA",
            description: "Pulsa todos los botones de un color a la vez.",
          },
          {
            name: "BUSCA Y CAPTURA",
            description: "Encuentra un patrón entre la multitud.",
          },
          {
            name: "APAGÓN",
            description: "Apaga los colores siguiendo un orden.",
          },
          {
            name: "SECUENCIAS",
            description: "Reproduce una secuencia de colores.",
          },
        ],
      },
      {
        name: "SALA MOSAICO",
        icon_src: new URL(
          "/src/assets/images/icons-sala(3).png",
          import.meta.url
        ).href,
        room_src: new URL("/src/assets/images/Mosaico-min.png", import.meta.url)
          .href,
        challenges: [
          {
            name: "CASCADA",
            description: "(COMPETITIVO) Sigue el ritmo para pisar las casillas.",
          },
          {
            name: "TRAVESÍA",
            description: "Llega al destino sin pisar las casillas rojas.",
          },
          {
            name: "GUERRA DE COLORES",
            description: "(COMPETITIVO) Llega a tu color antes que tus compañeros.",
          },
          {
            name: "PONTE A SALVO",
            description:
              "Corre hasta el color correcto antes de que se acabe el tiempo.",
          },
          {
            name: "ESQUIVA Y CONQUISTA",
            description:
              "Pisa las casillas azules sin que te pillen las rojas.",
          },
        ],
      },
      {
        name: "SALA LABERINTO",
        icon_src: new URL(
          "/src/assets/images/icons-sala(4).png",
          import.meta.url
        ).href,
        room_src: new URL("/src/assets/images/Laberinto-min.png", import.meta.url)
          .href,
        challenges: [],
      },
      {
        name: "SALA GAMING",
        icon_src: new URL(
          "/src/assets/images/icons-sala(5).png",
          import.meta.url
        ).href,
        room_src: new URL("/src/assets/images/Gaming-min.png", import.meta.url)
          .href,
        challenges: [],
      },
      {
        name: "SALA RADAR",
        icon_src: new URL(
          "/src/assets/images/icons-sala(6).png",
          import.meta.url
        ).href,
        room_src: new URL("/src/assets/images/Radar-min.png", import.meta.url)
          .href,
        challenges: [],
      },
    ]);

    return { rooms, roomSelected, changeRoomSelected , openModal, isModalOpened, closeModal};
  },
};
</script>

<style scoped>
.challenges-enter-active {
  transition: opacity 0.3s ease;
}
.challenges-enter-from,
.challenges-leave-to {
  opacity: 0;
}

.room-enter-active,
.room-leave-active {
  transition: opacity 0.3s ease;
}
.room-enter-from,
.room-leave-to {
  opacity: 0;
}

.video-shadow{
  text-shadow: 0px 0px 5px black;
}
</style>
