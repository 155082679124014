<template>
  <div class="FAQs">
    <div class="gaming-library-2">
      <div class="col-lg-12">
        <div class="heading-section">
          <h4>PREGUNTAS FRECUENTES</h4>
        </div>
        <div class="flex flex-col" v-for="faq in faqs" :key="faq">
          <div
            @click="faq[2] = !faq[2]"
            class="item flex flex-row w-full cursor-pointer"
          >
            <span class="flex flex-col" v-html="faq[0]" />
            <span class="flex flex-col">
              <i
                class="fa"
                :class="
                  faq[2] ? 'fa-circle-chevron-down' : 'fa-circle-chevron-left'
                "
              />
            </span>
          </div>
          <Transition name="answer">
            <div class="answer flex flex-row w-full" v-if="faq[2]">
              <p v-html="faq[1]" />
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "FAQsView",
  components: {},
  setup(props) {
    const router = useRouter();
    const { t } = useI18n();
    const faqs = ref([
      [t("faqs.faq1_q"), t("faqs.faq1_r"), false],
      [t("faqs.faq2_q"), t("faqs.faq2_r"), false],
      [t("faqs.faq3_q"), t("faqs.faq3_r"), false],
      [t("faqs.faq4_q"), t("faqs.faq4_r"), false],
      [t("faqs.faq5_q"), t("faqs.faq5_r"), false],
      [t("faqs.faq6_q"), t("faqs.faq6_r"), false],
      [t("faqs.faq7_q"), t("faqs.faq7_r"), false],
      [t("faqs.faq8_q"), t("faqs.faq8_r"), false],
      [t("faqs.faq9_q"), t("faqs.faq9_r"), false],
      [t("faqs.faq10_q"), t("faqs.faq10_r"), false],
      // [t("faqs.faq11_q"), t("faqs.faq11_r"), false],
    ]);
    onMounted(() => {});

    return { faqs };
  },
};
</script>

<style scoped>
.answer-enter-active {
  transition: all 0.5s ease-out;
}

.answer-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.answer-enter-from,
.answer-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
