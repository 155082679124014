<template>
  <div class="div-contact">
    <LayoutLanding>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-content">
              <!-- ***** Details Start ***** -->
              <div class="text-zone">
                <div class="row">
                  <div class="col-lg-10">
                    <hr class="d-none d-lg-block" style="width:1296px;color: rgba(0,0,0,0.01);">
                    <h2 class="mt-0">PONTE EN CONTACTO</h2>
                  </div>
                </div>
              </div>

              <!-- ***** Details End ***** -->
              <FormContact
                :formSchema="formSchema"
                :buttonFunction="sendData"
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutLanding>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import LayoutLanding from "@/components/LayoutLanding.vue";
import FormContact from "@/components/FormContact.vue";
import { axiosToken } from "../libs/http";
import { notify } from "notiwind";
export default {
  name: "ContactView",
  components: {
    LayoutLanding,
    FormContact,
  },
  setup(props) {
    onMounted(() => {});
    const sendData = (data) => {
      data["type_contact"] = 4;
      axiosToken({ method: "post", url: "landing/contact", body: data })
        .then((response) => {
          if (response.status == 201) {
            notify(
              {
                group: "success",
                title: "Exito",
                text: "Datos enviados",
              },
              4000
            );
          } else {
            notify(
              {
                group: "error",
                title: "Ya estas suscrito",
                text: "Vaya, parece que ya estas suscrito.",
              },
              4000
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const formSchema = ref({
      description:
        "Si tienes cualquier duda puedes contactarnos a través de este formulario. Estaremos encantados de responderte.",
      form: [
        {
          type: "input",
          name: "name",
          title: "Nombre y apellidos",
          placeholder: "ej: Ibai Llanos",
        },
        {
          type: "input",
          name: "email",
          title: "Correo",
          placeholder: "ej: ibai@koisquad.com",
        },
        {
          type: "textarea",
          name: "message",
          title: "Mensaje",
          placeholder: "ej: Estoy interesado en hacer una promo juntos... *",
        },
        {
          type: "button",
          name: "send",
          title: "Enviar",
        },
      ],
    });

    return { formSchema, sendData };
  },
};
</script>
