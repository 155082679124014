<template>
  <div class="div-ranking">
    <LayoutLanding>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-content">
              <!-- ***** Featured Games Start ***** -->
              <div class="row ranking text-zone">
                <div class="row">
                  <div class="col-lg-10">
                    <h2 class="mt-0">RÁNKING</h2>
                    <p class="main-p">
                      Consulta las puntuaciones de los jugadores en SPARK81
                    </p>
                    <hr class="d-none d-lg-block" style="width:1296px;color: rgba(0,0,0,0.01);">
                    
                    <p class="main-p">
                      <em>ESTE SERVICIO AÚN NO ESTÁ DISPONIBLE</em>
                    </p>
                    
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-10">
                    <!--
                    <div class="row search">
                      <div class="col-lg-6 col-8">
                        <form action="#" class="">
                          <input type="text" placeholder="ID de partida" id='searchText' name="searchKeyword" onkeypress="handle" />
                          <i class="fa fa-search"></i>
                        </form>
                      </div>
                      <div class="col-lg-2 col-4">
                        <div class="main-button col">
                          <a href="">BUSCAR</a>
                        </div>
                      </div>


                    </div>
                    -->
                  </div>
                </div>
              </div>
            </div>

            <!-- ***** Featured Games End ***** -->
          </div>
        </div>
      </div>
    </LayoutLanding>
  </div>
</template>

<script>
import { onMounted } from "vue";
import LayoutLanding from "@/components/LayoutLanding.vue";
export default {
  name: "RankingView",
  components: {
    LayoutLanding,
  },
  setup(props) {
    onMounted(() => {});

    return {};
  },
};
</script>
