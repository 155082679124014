<template>
  <div class="div-booking">
    <LayoutLanding>
      <div class="container p-0 xl:w-[1296px]">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-content p-4">
              <!-- ***** Featured Games Start ***** -->
              <div class="row ranking text-zone">
                <div class="row">
                  <div class="col-lg-10">
                      <h2 class="mt-8">RESERVAS</h2>
                      <p class="">Selecciona la fecha y hora que quieres venir a jugar e introduce tus datos. La reserva se confirma con un pago y señal de 30 €. <br>
                        Para reservas de <b>última hora</b> (menos de 12 horas de antelación), debes de llamarnos al +34 682 289 441.
                        <br>Para reservas de <b>grupos grandes</b> y cumpleaños (más de 6 personas), contacta con nosotros al +34 682 289 441 para confirmar disponibilidades y hacer la reserva.
                      </p>
                      </div>
                  <div class="col-lg-12">
                    <div id="app" data-type="booking" data-calendarstyle="Light" data-textstyle="Light" data-game="100815" data-calendar="100815" data-lang="es" data-token="aDSnC3Sx1R8AfbEi"></div>
                  
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-10">
                    <!--
                    <div class="row search">
                      <div class="col-lg-6 col-8">
                        <form action="#" class="">
                          <input type="text" placeholder="ID de partida" id='searchText' name="searchKeyword" onkeypress="handle" />
                          <i class="fa fa-search"></i>
                        </form>
                      </div>
                      <div class="col-lg-2 col-4">
                        <div class="main-button col">
                          <a href="">BUSCAR</a>
                        </div>
                      </div>


                    </div>
                    -->
                  </div>
                </div>
              </div>
            </div>
            <!-- ***** Featured Games End ***** -->
          </div>
        </div>
      </div>
    </LayoutLanding>
  </div>
</template>

<script>
import { onMounted } from "vue";
import LayoutLanding from "@/components/LayoutLanding.vue";
export default {
  name: "RankingView",
  components: {
    LayoutLanding,
  },
  setup(props) {
    onMounted(() => {
      let yourScript= document.createElement('script')
      yourScript.setAttribute('src', 'https://erdpanel.com/dist/build.js?v=' + Date.now())
      document.head.appendChild(yourScript)
    });

    return {};
  },
};
</script>
