import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import '@/assets/sass/app.scss'
import { i18n } from './i18n'
import Notifications from 'notiwind'
import InlineSvg from 'vue-inline-svg';
import bootstrap from 'bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "@/assets/css/fontawesome.css";
import "@/assets/css/templatemo-cyborg-gaming.css";
import "@/assets/css/owl.css";
import "@/assets/css/animate.css";
import "@/vendor/jquery/jquery.min.js";
import "@/vendor/bootstrap/js/bootstrap.min.js";
import "@/assets/js/isotope.js";
//import "@/assets/js/owl-carousel.js";
import "@/assets/js/tabs.js";
//import "@/assets/js/popup.js";
//import "@/assets/js/custom.js";
import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp({
  extends: App,
  /* beforeMount() {
      //works fine
  },
  beforeUnmount() {
      //doesn't work well
  },*/
}).use(store).use(router).use(i18n).use(Notifications)

app.config.globalProperties.$filters = {
  formatDate(value) {
    const date = new Date(value)
    return moment(date).format('DD/MM/YYYY HH:mm')
  },
  formatDateDay(value) {
    const date = new Date(value)
    return moment(date).format('DD/MM/YYYY')
  }
  ,
  formatDateHour(value) {
    const date = new Date(value)
    return moment(date).format('HH:mm ')
  }
}
app.component('inline-svg', InlineSvg);
app.use(VueReCaptcha, { siteKey: '6Lfww9cpAAAAACTpv9xcL4RLD4fqPqIY_-cthhxQ' })
app.mount('#app_SPARK81');
