<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="row col-lg-12 footer">
          <div class="col-lg-4 col-md-6">
            <div class="logo">
              <img src="@/assets/images/logo.png" alt="" />
            </div>
            <ul class="footer-widget__list">
              <li>
                <span class="footer-text">En  
                <a
                  href="https://thecityescaperoom.com/barcelona/"
                  target="_blank"
                  class="hover-style-link hover-style-link--green"
                  >The City Escape Room</a>
                  , en el Parc Vallès</span>
              </li>
              <li>
                <span class="footer-text">
                <a
                  href="https://maps.app.goo.gl/M6RHQ6HaXu7pHThz8"
                  target="_blank"
                  class="hover-style-link hover-style-link--green"
                  >(Carrer del Llobregat, 4B, Local 11, Terrassa)</a
                ></span>
              </li>
              <br>
              <li >
                <span class="footer-text"> <b>Reservas y atención al cliente:</b> 
                  <br>
                <a href="mailto:barcelona@thecityescaperoom.com" class="hover-style-link"
                  >barcelona@thecityescaperoom.com</a>
                  <br>
                  <a href="tel:682289441" class="hover-style-link">682289441</a>
                </span>
              </li>
              <br>
              <li >
                <span class="footer-text"> Contacto de prensa y franquicia: 
                  <br>
                <a href="mailto:hola@spark81.com" class="hover-style-link"
                  >hola@spark81.com</a>
                  <br>
                  <a href="tel:624329890" class="hover-style-link">624329890</a>
                </span>
              </li>
        
              <li>
                <span class="footer-text">
                <a
                  href="www.spark81.com"
                  target="_blank"
                  class="hover-style-link hover-style-link--green"
                  >www.spark81.com</a>
              </span>

              </li>
            </ul>
          </div>

          <div class="col-lg-4 col-md-6 footer-widget">
            <div class="row">
              <h6 class="footer-text">Nuestras redes</h6>
            </div>

            <div class="row">
              <a
                href="https://www.instagram.com/playspark81"
                target="_blank"
                class="social-link"
              >
                <i class="fab fa-instagram social-link-icon"></i>
              </a>
              <a
                href="https://www.tiktok.com/@playspark81"
                target="_blank"
                class="social-link"
              >
                <i class="fab fa-tiktok social-link-icon"></i>
              </a>
              <!--
                <a href="https://twitter.com/" target="_blank" class="social-link"> <i class="fab fa-facebook social-link-icon"></i> </a>
                -->
            </div>
          </div>
          <div class="col-lg-4 col-md-6 footer-widget">
            <h6 class="footer-text">Ponte en contacto</h6>
            <ul class="footer-widget__list">
              <li class="cursor-pointer">
                <a
                @click="
                      () => {
                        $router.push({ name: 'contact' });
                      }
                    "
                class="hover-style-link">Contacto</a>
              </li>
              <li class="cursor-pointer">
                <a
                @click="
                      () => {
                        $router.push({ name: 'events' });
                      }
                    "
                class="hover-style-link">Eventos privados</a>
              </li>
              <li class="cursor-pointer">
                <a 
                @click="
                      () => {
                        $router.push({ name: 'press' });
                      }
                    "
                 class="hover-style-link">Prensa</a>
              </li>
              <li class="cursor-pointer">
                <a 
                @click="
                      () => {
                        $router.push({ name: 'franchise' });
                      }
                    "
                 class="hover-style-link">Franquicia</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p>Copyright © 2023 <a href="#">TEAMING LABS</a></p>
    </div>
  </footer>
</template>

<script>
import { onMounted, ref } from "vue";
// @ is an alias to /src
export default {
  name: "FooterComponent",
  components: {},
  setup(props) {
    return {};
  },
};
</script>
