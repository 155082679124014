<template>
  <div class="div-events">
    <LayoutLanding>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-content">
              <!-- ***** Details Start ***** -->
              <div class="game-details-2">
                <div class="row">
                  <div class="col-lg-12">
                    <h2 class="mt-0">EVENTOS</h2>
                    <p class="main-p">
                      Nuestro espacio se puede reservar al completo para eventos
                      privados.
                    </p>
                  </div>
                  <div class="col-lg-12">
                    <div class="content row bg-dark">
                      <div class="col-lg-6">
                        <div class="left-info">
                          <h3>Pack fiesta de cumpleaños</h3>
                          <p>
                            ¿Quieres celebrar tu cumpleaños con nosotros? Ponte
                            en contacto para saber más sobre nuestras ofertas.
                          </p>
                          <div class="main-button">
                            <button @click="scrollForm">MONTA TU FIESTA</button>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 foto d-none d-md-block">
                        <img src="@/assets/images/events1.png" alt="" />
                      </div>
                    </div>
                    <div class="content row bg-dark-2">
                      <div class="col-lg-6 foto d-none d-md-block">
                        <img src="@/assets/images/events2.png" alt="" />
                      </div>
                      <div class="col-lg-6">
                        <div class="left-info">
                          <h3 class="text-right">Actividad de Team Building</h3>
                          <p class="text-right">
                            Si crees que tu empresa podría beneficiarse de una
                            sesión de juego en SPARK81, ponte en contacto con
                            nosotros para empezar a organizarlo.
                          </p>
                          <div class="main-button">
                            <button @click="scrollForm">
                              MONTA TU TEAM BUILDING
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="content row bg-dark">
                      <div class="col-lg-6">
                        <div class="left-info">
                          <h3>Alquiler del espacio</h3>
                          <p>
                            ¿Quieres grabar un videoclip? ¿Hacer una sesión de
                            fotos? ¿Tienes alguna otra idea? Escríbenos y
                            estaremos encantados de colaborar contigo.
                          </p>
                          <div class="main-button">
                            <button @click="scrollForm">SORPRÉNDENOS</button>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 foto d-none d-md-block" id="form-events-div">
                        <img src="@/assets/images/events3.png" alt="" />
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- ***** Details End ***** -->
              <FormContact
                id="title-rent"
                :formSchema="formSchema"
                :buttonFunction="sendData"
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutLanding>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import LayoutLanding from "@/components/LayoutLanding.vue";
import FormContact from "@/components/FormContact.vue";
import { axiosToken } from "../libs/http";
import { notify } from "notiwind";
export default {
  name: "EventsView",
  components: {
    LayoutLanding,
    FormContact,
  },
  setup(props) {
    onMounted(() => {});
    const sendData = (data) => {
      data["type_contact"] = 1;
      axiosToken({ method: "post", url: "landing/contact", body: data })
        .then((response) => {
          if (response.status == 201) {
            notify(
              {
                group: "success",
                title: "Exito",
                text: "Te hemos enviado un correo.",
              },
              4000
            );
          } else {
            notify(
              {
                group: "error",
                title: "Error",
                text: "Vaya, algo a ido mal.",
              },
              4000
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const scrollForm = () => {
      document.getElementById("title-rent").scrollIntoView();
    };
    const formSchema = ref({
      title: "RESERVA TU EVENTO",
      description:
        "Cuéntanos sobre tu petición y nuestro equipo de producción te ayuadará a montar un evento único en SPARK81.",
      form: [
        {
          type: "input",
          name: "name",
          title: "Nombre y apellidos",
          placeholder: "ej: Rosalía Vila",
        },
        {
          type: "input",
          name: "email",
          title: "Correo",
          placeholder: "ej: rosalia@motomami.es",
        },
        {
          type: "input",
          name: "event_date",
          title: "Fecha del evento",
          placeholder: "ej: 23 de abril 2024",
        },
        {
          type: "textarea",
          name: "message",
          title: "Mensaje",
          placeholder:
            "ej: Me gustaría grabar mi videoclip en vuestro esapacio... *",
        },
        {
          type: "button",
          name: "send",
          title: "Enviar",
        },
      ],
    });

    return { formSchema, sendData, scrollForm };
  },
};
</script>
