<template>
  <div class="FAQs">
    <div class="gaming-library-2">
      <div class="heading-section">
          <h4>PRECIOS</h4>
        </div>
      <div class="col-lg-12 justify-content-center d-flex">
        <div class="row col-lg-8 d-flex ">
          <table class="table prices">
          <tbody>
            <tr>
              <td class="players">2 JUGADORES</td>
              <td class="players-price">25 € / persona</td>
            </tr>
            <tr>
              <td class="players">3 JUGADORES</td>
              <td class="players-price">22 € / persona</td>
            </tr>
            <tr>
              <td class="players">4 JUGADORES</td>
              <td class="players-price">20 € / persona</td>
            </tr>
            <tr>
              <td class="players">5 JUGADORES</td>
              <td class="players-price">17 € / persona</td>
            </tr>
            <tr>
              <td class="players">6 JUGADORES O MÁS</td>
              <td class="players-price">15 € / persona</td>
            </tr>
          </tbody>
        </table>
        <span class="text-center mb-3">Para precios de grupos privados, <a class="a-nostyle" href="#" @click.prevent="() => {$router.push('/events#form-events-div')}">ponte en contacto.</a> </span>
        <div class="main-button text-center">
            <a href="#" @click.prevent="() => {$router.push('/booking')}">RESERVA AHORA</a>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
export default {
  name: "PricesView",
  components: {},
  setup(props) {
    onMounted(() => {});

    return { };
  },
};
</script>

