<template>
  <div class="div-press">
    <LayoutLanding>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-content">
              <!-- ***** Details Start ***** -->
              <div class="text-zone">
                <div class="row">
                  <div class="col-lg-10">
                    <h2 class="mt-0">PRENSA</h2>
                  </div>
                  <div class="col-lg-10">
                    <h3>¿Qué es SPARK81?</h3>
                    <p>
                      SPARK es el nuevo concepto de entretenimento que te hace
                      sentir dentro de un videojuego. La tecnología de nuestras
                      salas crea una experiencia inmersiva que pone a prueba
                      cuerpo y mente juagando en equipo <br />
                      Se trata de una forma de ocio activo que mezcla la
                      actividad física del deporte con la diversión y engagement
                      de los videojuegos arcade.
                    </p>

                    <div class="content row">
                      <div class="col-lg-5 foto">
                        <img src="@/assets/images/team.jpg" alt="" />
                      </div>
                      <div class="col-lg-7">
                        <div class="left-info">
                          <h3>¿Quién está detrás de SPARK81?</h3>
                          <p>
                            SPARK81 es el último proyecto de <a class="a-nostyle" href="https://teaminglabs.com/">Teaming Labs</a>, una
                            empresa dedicada a la gamifiacación y las
                            actividades de team building.
                            <br />
                            Para tirar adelante este proyecto se ha colaborado
                            con <a class="a-nostyle" href="https://thecityescaperoom.com/barcelona/">The City Escape Room</a>, expertos en gestión de
                            locales de escape room.
                          </p>
                          <!--
                      <h3>Fotografías y descripciones:</h3>
                      <div class="main-button">
                        <a href="#"><i class="fa-solid fa-download"></i>  DESCARGAR PACK DE COMUNICACIÓN</a>
                      </div>
                       -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ***** Details End ***** -->
              <FormContact
                :formSchema="formSchema"
                :buttonFunction="sendData"
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutLanding>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import LayoutLanding from "@/components/LayoutLanding.vue";
import FormContact from "@/components/FormContact.vue";
import { axiosToken } from "../libs/http";
import { notify } from "notiwind";
export default {
  name: "PressView",
  components: {
    LayoutLanding,
    FormContact,
  },
  setup(props) {
    onMounted(() => {});
    const sendData = (data) => {
      data["type_contact"] = 2;
      console.log(data);
      axiosToken({ method: "post", url: "landing/contact", body: data })
        .then((response) => {
          if (response.status == 201) {
            notify(
              {
                group: "success",
                title: "Exito",
                text: "Te hemos enviado un correo.",
              },
              4000
            );
          } else {
            notify(
              {
                group: "error",
                title: "Error",
                text: "Vaya, algo a ido mal.",
              },
              4000
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    const formSchema = ref({
      title: "CONTACTO DE PRENSA",
      description:
        "¿Quieres saber más sobre el proyecto? ¿Concertar una entrevista? No dudes en darnos un toque.",
      form: [
        {
          type: "input",
          name: "name",
          title: "Nombre y apellidos",
          placeholder: "ej: Jordi Évole",
        },
        {
          type: "input",
          name: "email",
          title: "Correo",
          placeholder: "ej: jevole@delbarrio.tv",
        },
        {
          type: "textarea",
          name: "message",
          title: "Mensaje",
          placeholder:
            "ej: Estoy escribiendo haciendo un reportaje sobre vosotros y me gustaría... *",
        },
        {
          type: "button",
          name: "send",
          title: "Enviar",
        },
      ],
    });

    return { formSchema, sendData };
  },
};
</script>
