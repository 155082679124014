<template>
  <header class="header-area header-sticky">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="main-nav">
            <!-- ***** Logo Start ***** -->
            <a href="#" @click.prevent="() => {$router.push('/')}" class="logo">
              <img src="@/assets/images/logo.png" alt="" />
            </a>
            <Transition name="fade" mode="out-in">
              <ul
                class="nav"
                :class="
                  navVisible && menuActive ? 'display: block' : 'display: none'
                "
                :key="'menu'"
              >
                <li v-for="item in menu" :key="item">
                  <a
                    @click="
                      () => {
                        $router.push({ name: item.name });
                      }
                    "
                    class="cursor-pointer"
                    :class="item.active ? 'active' : ''"
                  >
                    {{ item.nameShow }}
                    <i v-if="item.name == 'login'" class="fa fa-sign-in" />
                    <i v-if="item.name == 'booking'" class="fa fa-calendar" />
                  </a>
                </li>
              </ul>
            </Transition>
            <a
              class="menu-trigger"
              @click="toggleMenu"
              :class="{ active: menuActive }"
            >
              <span>Menu</span>
            </a>
            <!-- ***** Menu End ***** -->
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { onMounted, ref, watch, onUnmounted, computed } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "HeaderView",
  components: {},
  setup(props) {
    const route = useRoute();
    const navVisible = ref(false);
    const menu = ref([
      { nameShow: "Home", name: "home", active: false },
      { nameShow: "Ránking", name: "ranking", active: false },
      { nameShow: "Eventos", name: "events", active: false },
      { nameShow: "Prensa", name: "press", active: false },
      { nameShow: "Franquicia", name: "franchise", active: false },
      { nameShow: "Contacto", name: "contact", active: false },
      { nameShow: "Reserva", name: "booking", active: false },
      { nameShow: "Iniciar Sesión", name: "login", active: false },
    ]);

    onMounted(() => {
      menu.value.forEach((element) => {
        if (element.name == route.name) {
          element.active = true;
        }
      });
      if (window.innerWidth > 991) {
        navVisible.value = false;
      } else {
        navVisible.value = true;
        menuActive.value = false;
      }
    });

    let windowWidth = ref(window.innerWidth);

    const onWidthChange = () => (windowWidth.value = window.innerWidth);
    onMounted(() => window.addEventListener("resize", onWidthChange));
    onUnmounted(() => window.removeEventListener("resize", onWidthChange));

    watch(
      () => windowWidth.value,
      () => {
        if (windowWidth.value > 991) {
          navVisible.value = false;
        } else {
          navVisible.value = true;
          menuActive.value = false;
        }
      }
    );

    const menuActive = ref(false);

    const toggleMenu = () => {
      menuActive.value = !menuActive.value;
    };

    return { menu, menuActive, toggleMenu, navVisible };
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.transition-element {
  display: block;
}
</style>
