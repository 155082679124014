const es = {
  general: {
  },
  faqs: {
    faq1_q: "¿Qué es SPARK81?",
    faq1_r: "Spark81 es un <b>espacio de acción arcade</b>. En cada sala puedes encontrar diferentes retos a los que te enfrentarás con tu equipo para conseguir la puntuación más alta. Puedes venir a jugar tantas veces como quieras para superar tu puntuación.",
    faq2_q: "¿Cómo funciona SPARK81?",
    faq2_r: "Para jugar a SPARK81, debes juntar a un <b>grupo de 2-6 personas</b> y hacer una <b>reserva a través de la web</b>. Con tu reserva conseguirás <b>20 minutos de juego en cad una de las 3 salas</b>.",
    faq3_q: "¿Cuánto dura SPARK81?",
    faq3_r: "Las sesiones son de <b>60 minutos</b> repartidos en 20 minutos para cada sala. Cada nivel de cada reto dura entre 1 y 3 minutos, por lo que tendrás tiempo de jugar a diferentes retos en cada sala durante tu sesión. Debes <b>llegar 10 minutos antes</b> de que empiece tu sesión para registrarte si no quieres perder tiempo de juego.",
    faq4_q: "¿Puedo volver a jugar a SPARK81 si ya he participado una vez?",
    faq4_r: "Sí, de hecho en una única sesión de juego no podrás jugar a todos los juegos ni a todos los niveles. Por eso te <b>recomendamos volver a venir a jugar</b> para seguir sumando puntos e intentar superar el récord de cada sala.",
    faq5_q: "¿Puedo participar si tengo una patología preexistente?",
    faq5_r: "Al ser una experiencia de juego activo, SPARK81 requiere que los jugadores <b>corran, salten, trepen, gateen y lancen objetos</b> en muchas de sus salas de juego. Con el fin de crear una experiencia más inmersiva, hemos añadido elementos como <b>luces intermitentes, música alta y sonidos estridentes</b>. La mayoría de juegos de SPARK81 involucran la <b>diferenciación de colores</b>. Pedimos que aquellos con patologías preexistentes sean precavidos y consulten con su profesional médico antes de asistir.",
    faq6_q: "¿Qué tipo de ropa y calzado debo llevar?",
    faq6_r: "Al ser un espacio de juego activo, SPARK81 requiere de cierto movimiento. Por eso, recomendamos venir con <b>ropa y calzado cómodos y de deporte</b>. Por motivos de seguridad, los Game Masters tienen derecho a no permitir la entrada a las salas si el calzado no es apropiado para este tipo de actividad.",
    faq7_q: "¿Cuántas personas pueden jugar a la vez?",
    faq7_r: "En cada sala puede haber un equipo de <b>2 a 6 personas</b>. Para grupos más grandes se puede jugar hasta <b>18 personas repartidos en equipos</b>. Las reservas de grupos de más de 6 se tienen que hacer a través de email (barcelona{'@'}thecityescaperoom.com) o teléfono (682289441)",
    faq8_q: "¿Cómo puedo reservar?",
    faq8_r: "Puedes <b>reservar a través de nuestra web</b>. Si tienes algún problema puedes contactarnos en hola{'@'}spark81.com.",
    faq9_q: "¿Tengo que reservar con antelación?",
    faq9_r: "Para asegurarte la disponibilidad del espacio en tu horario preferido, te <b>recomendamos reservar con antelación</b>. También puedes pasarte por nuestro local durante las horas de apertura para ver si tenemos espacio disponible.",
    faq10_q: "¿Cuál es la edad mínima para participar?",
    faq10_r: "Los juegos de SPARK81 son exigentes física y mentalmente. Están <b >diseñados para mayores de 9 años, pero todos los jugadores son bienvenidos</b >. Para menores de 9 años, mínimo un adulto pagado debe jugar con el equipo. Para niños entre 11-14 años, un adulto debe estar presente en las instalaciones durante la sesión de juego.",
    faq11_q: "¿Puedo cancelar o modificar mi reserva?",
    faq11_r: "Puedes cancelar o modificar tu reserva de manera gratuita siempre que lo hagas con más de <b>48 horas de antelación</b>.",
  },
}

module.exports = {
  es
}
