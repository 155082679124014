<template>
  <div class="Subscribe">
    <div class="overlay-h3">
      <div id="newsletter-form" class="main-banner-2">
      <div class="row">
        <div class="col-lg-7">
          <h4>
            Apúntate a la <em>newsletter</em> para recibir novedades y
            descuentos exclusivos.
          </h4>
          <form @submit.prevent="sendData">
            <div class="row mb-3">
              <div class="col-lg-6 ">
                <input
                  class="input-custom"
                  name="con_name"
                  type=""
                  placeholder="Nombre"
                  v-model="formData['name']"
                  required
                />
              </div>
              <div class="col-lg-6">
                <input
                  class="input-custom"
                  name="con_email"
                  type="email"
                  placeholder="Email"
                  v-model="formData['email']"
                  required
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="main-button col">
                <button class="font-bold" type="submit">Suscríbete</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>
    
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { axiosToken } from "@/libs/http";
import { notify } from "notiwind";
export default {
  name: "SubscribevView",
  components: {},
  setup(props) {
    const formData = ref({ email: "", name: "" });
    const sendData = () => {
      axiosToken({
        method: "post",
        url: "landing/subscribe",
        body: formData.value,
      })
        .then((response) => {
          if (typeof response.data.error == "undefined") {
            notify(
              {
                group: "success",
                title: "Exito",
                text: "Te has suscrito correctamente.",
              },
              4000
            );
          } else {
            notify(
              {
                group: "error",
                title: "Ya estas suscrito",
                text: "Vaya, parece que ya estas suscrito.",
              },
              4000
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };
    onMounted(() => {});

    return { sendData, formData };
  },
};
</script>
