<template>
  <div class="layout-landing">
    <Header />
    <slot> </slot>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import "@/vendor/jquery/jquery.min.js";
import "@/vendor/bootstrap/js/bootstrap.min.js";
import "@/assets/js/isotope.js";
//import "@/assets/js/owl-carousel.js";
import "@/assets/js/tabs.js";
//import "@/assets/js/popup.js";
//import "@/assets/js/custom.js";
import Home from "@/components/Home.vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "LayoutLandingView",
  components: {
    Home,
    Header,
    Footer,
  },
  setup(props) {
    const router = useRouter();

    onMounted(() => {});

    return {};
  },
};
</script>
