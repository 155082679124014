import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RankingView from '../views/RankingView.vue'
import ContactView from '../views/ContactView.vue'
import FranchiseView from '../views/FranchiseView.vue'
import LoginView from '../views/LoginView.vue'
import PressView from '../views/PressView.vue'
import EventsView from '../views/EventsView.vue'
import BookingView from '../views/BookingView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: RankingView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/franchise',
    name: 'franchise',
    component: FranchiseView
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: (to, from, next) => {
      window.location.replace("https://app.spark81.com/login")
    }
  },
  {
    path: '/press',
    name: 'press',
    component: PressView
  },
  {
    path: '/events',
    name: 'events',
    component: EventsView
  },
  {
    path: '/booking',
    name: 'booking',
    component: BookingView
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
